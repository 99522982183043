<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item :label="$t('Title')" prop="title">
        <el-input type="text" v-model="form.title"></el-input>
      </el-form-item>

      <el-form-item :label="$t('Body')" prop="body">
        <el-input type="textarea" v-model="form.body" :rows="5"></el-input>
      </el-form-item>

      <picture-input
        ref="pictureInput"
        width="600"
        height="600"
        margin="0"
        accept="image/jpeg,image/png"
        size="10"
        :removable="true"
        button-class=" btn btn-primary"
        class="mb-4"
        @change="onImageChange"
      >
      </picture-input>

      <div class="d-flex justify-content-center" v-if="form.image && !form.urlImage">
        <el-button type="primary" size="small" class="mb-4 mx-auto" @click="S3ImageUpload"
          >Upload Image</el-button
        >
      </div>
      <el-form-item label="URL Only SMS" v-if="form.urlImage">
        <el-input type="text" v-model="form.urlSMS"></el-input>
      </el-form-item>

      <el-form-item label="Scheduled" prop="scheduled">
        <el-select
          v-model="form.scheduled"
          placeholder="Scheduled"
          class="w-100"
        >
          <el-option :value="false" label="Send Now">Send Now</el-option>
          <el-option :value="true" label="Scheduled">Scheduled</el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Birthday" prop="birthday">
        <el-select
          v-model="form.birthday"
          placeholder="Scheduled"
          class="w-100"
          :disabled="!form.scheduled"
        >
          <el-option :value="false" label="No">No</el-option>
          <el-option :value="true" label="Yes">Yes</el-option>
        </el-select>
      </el-form-item>

      <el-form-item
      :label="$t('Date')"
      prop="date"
    >
      <el-date-picker
        type="date"
        v-model="form.date"
        value-format="yyyy-MM-dd"
        format="MM/dd/yyyy"
        placeholder="Pick a day"
        class="w-100"
        :disabled="!form.scheduled || form.birthday"
      ></el-date-picker>
    </el-form-item>
      <el-button type="primary" @click="submitForm('form')"
        >Send Notification</el-button
      >
    </el-form>

  </div>
</template>

<script>
import PictureInput from "vue-picture-input";
import notification from "@/services/api/Notification";

export default {
  props: ["data"],
  data() {
    return {
      form: {
        shareholders: null,
        createdBy: this.$store.getters.id,
        title: null,
        body: null,
        image: null,
        urlImage: null,
        urlSMS: null,
        scheduled: false,
        birthday: false,
        date: null,
      },
    };
  },
  components: { PictureInput },
  mounted() {
    //
    if (this.data) {
      if (this.data.shareholders) {
        this.form.shareholders = this.data.shareholders.map((shareholder) => shareholder.id);
      }
      /* this.form.offices = this.data.offices;
      this.form.title = this.data.title;
      this.form.body = this.data.body; */
    }
  },
  methods: {
    onImageChange(image) {
      this.form.urlImage = null
      this.form.urlSMS = null
      if (image) {
        console.log("Picture loaded.");
        this.form.image = image;
      } else {
        this.form.image = null
        console.log("FileReader API not supported: use the <form>, Luke!");
      }
    },
    S3ImageUpload() {
      const data = {
        image: this.form.image
      }
      notification
            .S3ImageUpload(data)
            .then((response) => {
              if (response.result.status === "success") {
                this.form.urlImage = response.result.urlImage
                this.form.urlSMS = response.result.urlImage
              }
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error",
              });
            });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          notification
            .create(this.form)
            .then((response) => {
              if (response.result.status === "success") {
                this.$message({
                message: response.result.message,
                  type: "success",
                  customClass: "message-success",
                });
                this.$router.push('/admin/notification');
              }
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: 45px;
}
.style-chooser .vs__dropdown-menu {
  max-height: 150px;
}

.btn-edit-image {
  border: 1px solid black;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
