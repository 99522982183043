<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <div class="row">
      <div class="mb-2 ml-3 mr-2">
        <router-link to="/admin/notification">
        <el-button type="primary" size="small"
          >Back</el-button
        >
        </router-link>
      </div>
      <div class="mb-2" v-if="shareholdersSelected.length > 0">
        <el-button type="primary" size="small" @click="newNotification()"
          >Send Notification</el-button
        >
      </div>
    </div>
    <div class="row mb-2 justify-content-between">
      <div v-if="discriminator === 'administrator'" class="col-6 ml-0 pr-2">
        <multiselect
          class="multiselect-top"
          v-model="officeFilter"
          @input="filterData"
          :options="offices"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Offices"
          label="office_name"
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.office_name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
      <div class="col-6 ml-0 pr-2">
        <multiselect
          class="multiselect-top"
          v-model="clientFilter"
          @input="filterData"
          :options="clients"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Client"
          label="name"
          track-by="uuid"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
    </div>
    <div class="row mb-4">
    <div class="col-3 ml-0 pr-2">
      <date-picker type="date" v-model="dateFilter" @change="filterData" range format="MM/DD" placeholder="Select Range Date"></date-picker>
    </div>
    <div class="col-3 ml-0 pr-2">
      <v-select
        class="style-chooser w-100"
        placeholder="Age From"
        @input="filterData"
        :options="ageRange"
        v-model="ageFromFilter"
      ></v-select>
    </div> 
    <div class="col-3 ml-0 pr-2">
      <v-select
        class="style-chooser w-100"
        placeholder="Age To"
        @input="filterData"
        :options="ageRange"
        v-model="ageToFilter"
      ></v-select>
    </div>
    <div class="col-3 ml-0 pr-2">
      <v-select
        class="style-chooser w-100"
        placeholder="Select Status"
        @input="filterData"
        :options="status"
        label="status"
        v-model="statusFilter"
      ></v-select>
    </div>   
    </div>
    <el-table
      :data="
        this.tableData.filter(
          (data) =>
            !search ||
            data.office_name.toLowerCase().includes(search.toLowerCase()) ||
            data.manager_name.toLowerCase().includes(search.toLowerCase()) ||
            data.phone.toLowerCase().includes(search.toLowerCase()) ||
            data.email.toLowerCase().includes(search.toLowerCase())
        )
      "
      class="w-100"
    >
       <el-table-column
        prop="discriminator"
        label="Type"
        sortable
        :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column
        prop="corporates"
        label="Client"
        sortable
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
            {{scope.row.corporates.length > 0? scope.row.corporates[0].name: ''}}
        </template>
      </el-table-column>

      <el-table-column
        prop="name"
        label="Contact"
        sortable
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
            {{ scope.row.lastName + ' ' +scope.row.name}}
        </template>
      </el-table-column>

      <el-table-column
        prop="phone"
        label="Phone"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        prop="birthDay"
        label="DOB"
        sortable
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
            scope.row.birthDay | moment("MM/DD/YY")
          }}</template>
      </el-table-column>

      <el-table-column
        prop="age"
        label="Age"
        sortable
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        prop="office.office_name"
        label="Office"
        sortable
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column>
        <template slot="header">
          <label for="select-all">Send Message?</label>
          <input
            id="select-all"
            class="form-check-input ml-2"
            type="checkbox"
            v-on:change="selectAllOnTable()"
            v-model="selectAllTable"
          />
        </template>
        <template slot-scope="scope">
          <div class="form-check">
            <input
              class="form-check-input"
              v-model="scope.row.selected"
              v-on:change="updateSelection()"
              type="checkbox"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
      title="New Notification"
      :visible.sync="component.drawer"
      :before-close="handleClose"
      direction="rtl"
      size="500px"
    >
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-new-notification
          v-on:refresh="load($event)"
          :data="component.data"
          :key="component.render"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import office from "@/services/api/office";
import corporate from "@/services/api/corporate";
import officeUser from "@/services/api/officeUser";
import shareholder from "@/services/api/shareholder";
import ComponentNewNotification from "@/components/admin/NewNotification";

import Multiselect from "vue-multiselect";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    ComponentNewNotification,
    Multiselect,
    DatePicker
  },
  data() {
    return {
      selectAllTable: false,
      shareholdersSelected: [],
      officeFilter: [],
      clientFilter: [],
      statusFilter: [],
      dateFilter:[null,null],
      ageFromFilter: null,
      ageToFilter: null,
      offices: [],
      clients: [],
      status: [{ status: "Upcoming" }, { status: "Scheduled" }, { status: "Done" }],
      ageRange: Array.from({length: 100}, (_, i) => i + 1),
      filter: {},
      tableData: [],
      data: [],
      search: "",
      discriminator: "",
      component: {
        data: null,
        drawer: false,
        render: 0,
        index: null,
      },
    };
  },
  mounted() {
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee": {
        this.discriminator = "office";
        let idOffice = null;
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            idOffice = item.office.id;
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
      }
    }
    this.getShareholders()
  },
  methods: {
    getShareholders() {
      shareholder.shareholdersNotifications(this.filter).then((response) => {
        this.data = Object.assign([], response);
        this.tableData = response;
        response.forEach((office) => {
          office.selected = false;
        });
      });
    },
    selectAllOnTable() {
      let shareholders = Object.assign([], this.data);
      if (this.selectAllTable) {
        shareholders.forEach((element) => {
          if (element.selected === false) {
            element.selected = true;
          }
        });
      } else {
        shareholders.forEach((element) => {
          element.selected = false;
        });
      }

      this.tableData = Object.assign([], shareholders);
      this.updateSelection();
    },
    getClients(idOffices) {
      this.clients = [];
      idOffices.forEach((id) => {
        corporate.getClientsByOffice(id).then((response) => {
          this.clients = this.clients.concat(response);
        });
      });
    },
    filterData() {
      let idOffices = this.officeFilter.map((office) => {
        return office.id;
      });

      let clients = this.clientFilter.map((client) => {
        return client.id;
      });

      this.filter = {}

      if (idOffices.length > 0) {
        this.filter.offices = idOffices
      }

      if (idOffices.length !== this.lengthOfficeFilter) {
        this.lengthOfficeFilter = idOffices.length;
        this.getClients(idOffices);
      }
      if (idOffices.length == 0) {
        this.lengthOfficeFilter = 0;
        this.clientFilter = [];
        this.clients = [];
      }

      if (clients.length > 0) {
        this.filter.clients = clients
      }

      if(this.dateFilter[0]!=null && this.dateFilter[1]!=null){
        this.filter.dateFilter = this.dateFilter
      }

      if(this.ageFromFilter !== null){
        this.filter.ageFrom = this.ageFromFilter
      }

      if(this.ageToFilter !== null){
        this.filter.ageTo = this.ageToFilter
      }

      if(this.statusFilter !== null){
        this.filter.status = this.statusFilter.status
      }

      this.getShareholders()
    },
    newNotification(row = null) {
      let shareholders = [];
      if (row) {
        shareholders.push({ id: row.id, name: row.name });
      } else {
        this.shareholdersSelected.forEach((office) =>
          shareholders.push({ id: office.id, name: office.name })
        );
      }
      this.$swal({
        title: "New Notification",
        text:
          "You will create a new notification",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, continue!",
      }).then((result) => {
        if (result.value) this.add({ shareholders: shareholders });
        return;
      });
    },
    updateSelection() {
      this.shareholdersSelected = this.tableData.filter(
        (office) => office.selected === true
      );
    },
    load(event) {
      this.component.drawer = false;
      if (event.update) {
        this.tableData.splice(this.index, 1, event.data);
      } else {
        this.tableData.unshift(event.data);
      }
    },
    add(data = null) {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = data ? data : null;
    },
    handleClose(done) {
      this.$confirm("Are you sure you want to close this?")
        .then((_) => {
          this.component.data = null;
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">

.mx-datepicker{
    width:100% !important;
  }

  .mx-input{
    height: 42px !important;
  }
</style>
